import { AnalyseBusiness, DetailFinacialHealth, FormAnalyzeBusiness } from 'containers/Analysis/interfaces'
import { UpdateGrossSalary } from 'containers/Groups/interfaces'
import { RestApi } from 'resources/services/interfaces'

const companyService = (_api: RestApi) => ({
  getCompanies: async () => _api.get('/backoffice/companies'),
  getCompany: async (companyId: string) => _api.get(`/backoffice/companies/${companyId}`),
  getGroupNames: async () => _api.get('/backoffice/departments/'),
  getDepartmentAndBusinessInfo: async (groupId: string) => _api.get(`backoffice/departments/${groupId}/`),
  getCompaniesOfDepartmentInfo: async (groupId: string) => _api.get(`backoffice/departments/${groupId}/companies/`),
  listBusiness: async () => _api.get('backoffice/business'),
  addBusiness: async (business: FormAnalyzeBusiness) => _api.post<any>('backoffice/business', {name: business.name}),
  getFinancialHealthCompany: async (document: string) => _api.get<DetailFinacialHealth>(`backoffice/companies/${document}/financial-health`),
  analyseBusiness: async (id: string, data: AnalyseBusiness) => _api.post(`backoffice/business/${id}/financial-health`, data),
  syncCompanyHelpCenterData: async (companyId: string) => _api.post(`/backoffice/companies/${companyId}/sync-help-center`),
  enableInternalAccess: async (departmentId: string) => _api.put(`/backoffice/departments/${departmentId}/enable-internal-access`, {}),
  updateGrossSalary: async (id: string, data: UpdateGrossSalary) => _api.put(`backoffice/departments/${id}/gross-salary-limit`, data),
  updateEmailDomains: async (id: string, data: {emailDomains: string[]}) => _api.put(`backoffice/business/${id}/email-domains`, data),
})

export default companyService