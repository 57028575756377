import { toQueryString } from 'utils/service'
import { RestApi } from '../interfaces'
import {
  ActivePlannerRequest,
  CreateScheduleRequest,
  ListAvailableTimesFilters,
  ListPlannersFilter,
  RescheduleRequest,
  SchedulingFilter,
  SaveMeetingRequest,
  MeetingFilter,
  FinancialPlanningAIFilter,
  LatestSummaryFinancialPlanningFilter,
  FinancialPlanningReviewFilter
} from './interfaces'

const financialPlanningService = (_api: RestApi) => ({
  invitePlanner: async (email: string) =>
    _api.post('/backoffice/financial-planning/planners', { email }),
  activePlanner: async (data: ActivePlannerRequest) =>
    _api.put(`/backoffice/financial-planning/planners/${data.email}/activate`, {
      oAuthGoogleCode: data.oAuthGoogleCode,
      calendlyToken: data.calendlyToken
    }),
  deactivatePlanner: async (email: string) =>
    _api.put(`/backoffice/financial-planning/planners/${email}/inactivate`, {}),
  listAvailableTimes: async (filter: ListAvailableTimesFilters) =>
    _api.get(`/backoffice/financial-planning/schedulings/available-times?${toQueryString(filter)}`),
  createSchedule: async (data: CreateScheduleRequest) => {
    const { customerId } = data
    delete data.customerId
    _api.post(`/backoffice/financial-planning/${customerId}/schedule`, data)
  },
  listSchedulings: async (filter: SchedulingFilter) =>
    _api.get(`/backoffice/financial-planning/schedulings?${toQueryString(filter)}`),
  listPlanners: async (filter: ListPlannersFilter) =>
    _api.get(`/backoffice/financial-planning/planners?${toQueryString(filter)}`),
  startScheduling: async (schedulingId: string, plannerEmail: String) =>
    _api.post(`/backoffice/financial-planning/schedulings/${schedulingId}/start`, {}, { plannerEmail }),
  noShowScheduling: async (schedulingId: string) =>
    _api.post(`/backoffice/financial-planning/schedulings/${schedulingId}/noshow`),
  reschedule: async (schedulingId: string, data: RescheduleRequest) =>
    _api.post(`/backoffice/financial-planning/schedulings/${schedulingId}/reschedule`, data),
  customerLastMeeting: async (customerId: string) =>
    _api.get(`/backoffice/financial-planning/customers/${customerId}/meetings/last`),
  saveFinancialPlanning: async (meetingId: string, body: SaveMeetingRequest) =>
    _api.put(`/backoffice/financial-planning/meetings/${meetingId}/save`, body),
  closeFinancialPlanning: async (meetingId: string, body: SaveMeetingRequest) =>
    _api.post(`backoffice/financial-planning/meetings/${meetingId}/close`, body),
  getMeetings: async (customerId: string) =>
    _api.get(`/backoffice/financial-planning/customers/${customerId}/meetings`),
  listMeetings: async (filter: MeetingFilter) =>
    _api.get(`/backoffice/financial-planning/meetings?${toQueryString(filter)}`),
  getMeeting: async (meetingId: string) =>
    _api.get(`/backoffice/financial-planning/meetings/${meetingId}`),
  getFinancialPlanningAI: async (document: string) =>
    _api.get(`/backoffice/financial-planning/meetings/${document}`),
  createPlanning: async (data: any) => _api.post('/backoffice/financial-planning/schedulings', data),
  updateFinancialPlanningAI: async (document: string, data: any) => _api.put(`/backoffice/financial-planning/ai/customer/${document}/edit`, data),
  listFinancialPlanningAI: async (filter: FinancialPlanningAIFilter) => _api.get(`/backoffice/financial-planning?${toQueryString(filter)}`),
  getLatestSummaryFinancialPlanning: async (filter: LatestSummaryFinancialPlanningFilter) => _api.get(`/backoffice/financial-planning/latest-summary?${toQueryString(filter)}`),
  getFinancialPlanningReview: async (filter: FinancialPlanningReviewFilter) => _api.get(`/backoffice/financial-planning/review?${toQueryString(filter)}`),
  getOpenFinanceAllData: async (document: string, consentId: string) => _api.post(`/backoffice/financial-planning/open-finance/start-load-data?document=${document}&consentId=${consentId}`),
  returnFinancialPlanningAI: async (document: string) => _api.get(`/backoffice/financial-planning/open-finance/return-financial-planning?document=${document}`),
  updateFinancialPlanningReview: async (financialPlanningId: string, data: any) => _api.put(`backoffice/financial-planning/${financialPlanningId}/review`, data),
  createFinancialPlanning: async (data: any) => _api.post('backoffice/financial-planning', data),
  createManualFinancialPlanning: async (data: any) => _api.post('backoffice/financial-planning/manual', data),
  reprocessFinancialPlanning: async (financialPlanningId: string) => _api.post(`backoffice/financial-planning/${financialPlanningId}/reprocess`),
  completeAction: async (financialPlanningId: string, actionId: string) => _api.put(`backoffice/financial-planning/${financialPlanningId}/actions/${actionId}/complete`, {}),
  getFinancialChallenges: async () => _api.get('/backoffice/financial-planning/financial-challenges')
})

export default financialPlanningService