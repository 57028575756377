/* eslint-disable no-restricted-globals */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-extraneous-dependencies */
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Input } from 'reactstrap'
import { Move, PlusSquare, X } from 'react-feather'
import Button from 'components/Button'

interface DraggableListItemProps {
  item: any
  index: number
  readonly?: boolean
  asInput?: boolean
  moveItem: (dragIndex: number, hoverIndex: number) => void
  updateItem: (index: number, newText: string) => void
  removeItem: (index: number) => void
}

interface DraggableListProps {
  data: Array<any>
  readonly?: boolean
  onChange: (items: ActionItemWithoutId[]) => void;
}

export type ActionItemWithoutId = Omit<any, 'id'>

const DraggableListItem: FC<DraggableListItemProps> = ({ item, index, moveItem, updateItem, removeItem, readonly = false, asInput = true }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: 'LIST_ITEM',
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index && !readonly) {
        moveItem(draggedItem.index, index)
        draggedItem.index = index
      }
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'LIST_ITEM',
    item: { index },
    collect: (monitor) => ({
      isDragging: !readonly && monitor.isDragging()
    })
  })

  drag(drop(ref))

  return (
    <div
      key={`item-action-${index}-${item.actionId}`}
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1, display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
      {asInput ? <Input
        type="text"
        readOnly={readonly}
        value={item.description}
        onChange={(e) => updateItem(index, e.target.value)}
        style={{ marginRight: '8px' }}
      /> : <textarea
        rows={2}
        readOnly={readonly}
        value={item.description}
        onChange={(e) => updateItem(index, e.target.value)}
        className="form-control"
        style={{ resize: 'none', transition: 'height 0.2s ease' }}
      />}
      {!readonly &&
        <>
          <X color='red' size={40} onClick={() => removeItem(index)} style={{ marginRight: '8px', cursor: 'pointer' }} />
          <Move style={{ marginRight: '8px', cursor: 'move' }} />
        </>
      }
    </div>
  )
}

const DraggableList: FC<DraggableListProps> = ({ data, onChange, readonly = false }) => {
  const [items, setItems] = useState<any[]>([])

  const moveItem = useCallback((fromIndex: number, toIndex: number) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const [movedItem] = updatedItems.splice(fromIndex, 1)
      updatedItems.splice(toIndex, 0, movedItem)

      const prioritizedItems = updatedItems.map((item, index) => ({
        ...item,
        priority: index + 1,
      }))

      onChange(prioritizedItems)
      return updatedItems
    })
  }, [onChange])

  const addItem = () => {
    const newItem = { id: items.length + 1, actionId: null, description: '', priority: items.length + 1 }
    const updatedItems = [...items, newItem]
    setItems(updatedItems)
    onChange(updatedItems)
  }

  const updateItem = (index: number, newText: string) => {
    const updatedItems = items.map((item, i) => i === index ? { ...item, description: newText } : item)
    setItems(updatedItems)
    onChange(updatedItems)
  }

  const removeItem = (index: number) => {
    const updatedItems = items.filter((_, i) => i !== index)

    const prioritizedItems = updatedItems.map((item, index) => ({
      ...item,
      priority: index + 1,
    }))

    setItems(prioritizedItems)
    onChange(prioritizedItems)
  }

  useEffect(() => {
    if (JSON.stringify(items) !== JSON.stringify(data)) {
      setItems(data)
    }
  }, [data])

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {items.map((item, index) => (
          <DraggableListItem
            key={item.actionId ?? `action-${index}-${item.actionId}`}
            index={index}
            item={item}
            readonly={readonly}
            moveItem={moveItem}
            updateItem={updateItem}
            removeItem={removeItem}
            asInput={false}
          />
        ))}
        {!readonly && <Button outline color='primary' size='sm' text='Adicionar nova ação' icon={PlusSquare} onClick={addItem} />}
      </div>
    </DndProvider>
  )
}

export default DraggableList