import { RestApi } from 'resources/services/interfaces'
import { toQueryString } from 'utils/service'
import { FilterAdvance, FilterAdvanceCustomer } from './interfaces'

const orderService = (_api: RestApi) => ({
  listOrders: async (filter: FilterAdvance, config?: any) =>
    _api.get(`/backoffice/orders?${toQueryString(filter)}`, config),
  listOrdersCustomer: async (filter: FilterAdvanceCustomer) => {
    const { customerId, ...rest } = filter
    const data = await _api.get(
      `/backoffice/orders/customers/${customerId}?${toQueryString(rest)}`
    )
    return data
  },
  getOrder: async (orderId: string) =>
    _api.get(`/backoffice/orders/${orderId}`),
  listStatements: async (typeRequest: string, customerId: string) =>
    _api.get(`/backoffice/statements/${typeRequest}?CustomerId=${customerId}`),
  getHighestAmountCreditAnalysis: async (personId: string) =>
    _api.get(
      `/backoffice/consignment/persons/${personId}/credit-analysis/highest-amount`
    ),
  startCreditAnalysis: async (customerId: string) =>
    _api.post('/backoffice/consignment/credit-analysis/start', { customerId, type: 'preapproved' }),
  ordersInInternalApproval: async () =>
    _api.get('/backoffice/orders/request-internal-review'),
  orderReview: async (orderId: string) =>
    _api.get(`backoffice/orders/${orderId}/review`),
  approveOrder: async (orderId: string, email: string) =>
    _api.get(`/backoffice/orders/${orderId}/approve/${email}`),
  approveOrderList: async (orderIdList: Array<string>, email: string) =>
    _api.post(`backoffice/orders/approve/${email}`, orderIdList),
  denyOrder: async (orderId: string, email: string) =>
    _api.get(`/backoffice/orders/${orderId}/deny/${email}`),
  denyOrderList: async (orderIdList: Array<string>, email: string) =>
    _api.post(`backoffice/orders/deny/${email}`, orderIdList),
  getConsents: async (document: string) => _api.get(`/backoffice/open-finance/consents?document=${document}`),
  getMccs: async () => _api.get('/backoffice/open-finance/mcc')
})

export default orderService
