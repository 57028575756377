import { RestApi } from '../interfaces'
import { UnblockProductByPolicyRequest, ActivateDeactivateSubscriptionResponse } from './interfaces'

const subscriptionService = (_api: RestApi) => ({
  list: async (customerId: string) => _api.get(`/backoffice/subscriptions/customers/${customerId}`),
  getCompaniesSubscriptions: async (groupId: string) => _api.get(`backoffice/departments/${groupId}/subscriptions`),
  unblockByPolicy: async (data: UnblockProductByPolicyRequest) => _api.post('backoffice/subscriptions/products/unblock-policy', data),
  activateProductForCustomer: async (customerId: string, product: string) => _api.put<ActivateDeactivateSubscriptionResponse>('backoffice/subscriptions/activate-product', { customerId, product }),
  deactivateProductForCustomer: async (customerId: string, product: string) => _api.put<ActivateDeactivateSubscriptionResponse>('backoffice/subscriptions/deactivate-product', { customerId, product }),
})

export default subscriptionService