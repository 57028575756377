import { defaultTheme } from 'providers/Theme/theme'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Archive, Layers, PlusCircle, XCircle } from 'react-feather'
import { Col, FormGroup, Input, Label, Row, Tooltip } from 'reactstrap'
import { ToDoItem, ToDoListProps } from './interfaces'
import { RowBorderedStyled, RowItemStyled } from './styles'

const ToDoList: FC<ToDoListProps> = ({
  title,
  data,
  emptyMessage = 'Nenhum item criado',
  readonly = false,
  id = 'todolist',
  showArchivedItems = false,
  showValueInput = true,
  setItems = null,
  asInput = true
}) => {
  const [items, setLocalItems] = useState<Array<ToDoItem>>([])
  const [archivedItems, setArchivedItems] = useState<Array<ToDoItem>>([])
  const [openTooltipAddItem, setOpenTooltipAddItem] = useState<boolean>()
  const [openTooltipLayers, setOpenTooltipLayers] = useState<boolean>()
  const [showItemsArchived, setShowItemsArchived] = useState<boolean>(showArchivedItems)

  const previousLocalItems = useRef<ToDoItem[]>([])

  const handleRemoveItem = (id: string | number) => {
    const updatedItems = items.filter((item: ToDoItem) => item.id !== id)
    setLocalItems(updatedItems)
    if (setItems)
      setItems(updatedItems)
  }

  const handleArchiveItem = (id: string | number) => {
    const archivedItem = items.find((item: ToDoItem) => item.id === id)
    if (archivedItem) {
      archivedItem.status = 'inactive'
      setArchivedItems([...archivedItems, archivedItem])
      handleRemoveItem(id)
    }
  }

  const handleChangeLabel = (id: string | number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault()
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, label: event.target.value } : item
    )
    setLocalItems(updatedItems)

    if (setItems)
      setItems(updatedItems)
  }

  const handleChangeValue = (id: string | number, event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, value: event.target.value } : item
    )
    setLocalItems(updatedItems)

    if (setItems)
      setItems(updatedItems)
  }

  const renderItem = (item: ToDoItem) => (
    <RowItemStyled key={item.id} className='mt-1'>
      <Col xl={showValueInput ? '8' : '11'}>
        {asInput ? <Input
          value={item.label}
          
          onChange={(e) => handleChangeLabel(item.id, e)}
          placeholder='Descrição'
          readOnly={readonly}
          disabled={item.status === 'inactive'}
        />: <textarea
          rows={2}
          className="form-control"
          style={{ resize: 'none', overflow: 'hidden', transition: 'height 0.2s ease' }}
          value={item.label}
          onChange={(e) => handleChangeLabel(item.id, e)}
          placeholder='Descrição'
          readOnly={readonly}
          disabled={item.status === 'inactive'}
        />}
      </Col>
      {showValueInput &&
        <Col xl='2'>
          <Input
            type='text'
            value={item.value}
            onChange={(e) => handleChangeValue(item.id, e)}
            placeholder='Valor'
            readOnly={readonly}
            disabled={item.status === 'inactive'}
          />
        </Col>
      }
      {!readonly && item.status !== 'inactive' ?
        <>
          <Col xl='1'>
            <XCircle color={defaultTheme.palette.error.main} onClick={() => handleRemoveItem(item.id)} />
          </Col>
          {showItemsArchived &&
            <Col xl='1' className='mt-1'>
              <Archive color={defaultTheme.palette.secondary.main} onClick={() => handleArchiveItem(item.id)} />
            </Col>
          }
        </>
        : ('')
      }
    </RowItemStyled>
  )

  const handleAddItem = () => {
    if (!readonly) {
      const newItem = {
        label: '',
        value: '',
        id: items.length + 1,
        tooltipOpen: false,
        status: 'active',
      }

      const updatedItems = [...items, newItem]
      setLocalItems(updatedItems)

      if (setItems)
        setItems(updatedItems)
    }
  }

  const handleSetItems = useCallback(() => {
    if (setItems && JSON.stringify(items) !== JSON.stringify(previousLocalItems.current)) {
      setItems(items)
      previousLocalItems.current = items
    }
  }, [items, setItems])

  useEffect(() => {
    if (items.length > 0) {
      handleSetItems()
    }
  }, [items, handleSetItems])

  useEffect(() => {
    if (data && data.length > 0) {
      const itemsActive = data.filter((item: ToDoItem) => item.status === 'active')
      const itemsArchived = data.filter((item: ToDoItem) => item.status === 'inactive')
      setLocalItems(itemsActive)
      setArchivedItems(itemsArchived)
    }
  }, [data])

  return (
    <div id={id}>
      <Row className='mb-1'>
        <Col xl='10'>
          <Label>{title}</Label>
        </Col>
        <Col xl='2' style={{ display: 'flex', justifyContent: 'end' }}>
          {showItemsArchived && <>
            <Layers id='layers' style={{ cursor: 'pointer' }} onClick={() => setShowItemsArchived(!showItemsArchived)} />
            <Tooltip target='layers' placement='top' isOpen={openTooltipLayers} toggle={() => setOpenTooltipLayers(!openTooltipLayers)}>
              Metas concluídas/arquivadas
            </Tooltip>
          </>}

          {!readonly &&
            <>
              <PlusCircle color={defaultTheme.palette.primary.main} className='ml-1' id='addItem' onClick={handleAddItem} style={{ cursor: 'pointer' }} />
              <Tooltip target='addItem' placement='top' isOpen={openTooltipAddItem} toggle={() => setOpenTooltipAddItem(!openTooltipAddItem)}>
                Adicionar um novo item
              </Tooltip>
            </>
          }
        </Col>
      </Row>
      {items.length === 0 && <RowBorderedStyled className='centered'><span>{emptyMessage}</span></RowBorderedStyled>}
      {items.length > 0 &&
        <FormGroup>
          {items.map((item: ToDoItem) => renderItem(item))}
        </FormGroup>
      }
      {archivedItems.length === 0 && showItemsArchived &&
        <Row className='centered'><span style={{ fontStyle: 'italic' }}>Nenhum meta concluída/arquivada</span></Row>
      }
      {archivedItems.length > 0 && showItemsArchived &&
        <>
          <Col xl='12'>
            <h6>Metas concluídas/arquivadas</h6>
          </Col>
          <FormGroup>
            {archivedItems.map((item: ToDoItem) => renderItem(item))}
          </FormGroup>
        </>
      }
    </div>
  )
}

export default ToDoList