/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Button from 'components/Button'
import DraggableList, { ActionItemWithoutId } from 'components/DraggableList'
import { FC, useEffect, useState } from 'react'
import { Plus, X } from 'react-feather'
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap'

interface FinancialProblemsListProps {
  id: string
  data: Array<FinancialProblemItem>
  readonly?: boolean
  onChange: (updatedItems: Array<FinancialProblemItem>) => void
}

export interface FinancialProblemItem {
  id: string | number
  title: string
  description: string
  actions: Array<FinancialProblemAction>
}

export interface FinancialProblemAction {
  description: string
  priority: number
  completed: boolean
  completedAt?: Date
  actionId?: string
}

const FinancialProblemsList: FC<FinancialProblemsListProps> = ({ data, id, onChange, readonly = false }) => {
  const [items, setItems] = useState<Array<FinancialProblemItem>>([])

  useEffect(() => {
    if (data && data.length > 0) {
      setItems(data)
    }
  }, [data])

  const handleRemoveItem = (id: string | number) => {
    const updatedItems = items.filter((item: FinancialProblemItem) => item.id !== id)
    setItems(updatedItems)
    onChange(updatedItems)
  }

  const handleAddItem = () => {
    const newItem = { title: '', description: '', id: items.length + 1, actions: [] }
    const updatedItems = [...items, newItem]
    setItems(updatedItems)
    onChange(updatedItems)
  }

  const handleInputChange = (index: any, field: 'title' | 'description', value: string) => {
    const updatedItems = [...items]
    updatedItems[index] = { ...updatedItems[index], [field]: value }
    setItems(updatedItems)
    onChange(updatedItems)
  }

  const handleDraggableItemsChange = (actions: ActionItemWithoutId[], index: number) => {
    const convertedActions: FinancialProblemAction[] = actions.map(action => ({
      actionId: action.actionId,
      completed: action.completed,
      completedAt: action.completedAt,
      description: action.description,
      priority: action.priority
    }))

    const updatedItems = [...items]
    updatedItems[index] = { ...updatedItems[index], actions: convertedActions }
    setItems(updatedItems)
    onChange(updatedItems)
  }

  return (
    <div id={id}>
      <Row className='mb-1'>
        <Col xl='12'>
          <Label>Problemas financeiros</Label>
        </Col>
      </Row>
      <Row>
        <Col xl='12'>
          <Card style={{ backgroundColor: '#f4f4f4' }}>
            <CardBody>
              {items?.length === 0 && <Row>
                <Col xl='12'>
                  <p style={{fontStyle: 'italic'}}>Nenhum problema financeiro cadastrado</p>
                </Col>
              </Row>}
              {items.map((item, index) => (
                <Card
                  key={`financial-problem-${item.id}`}
                  className='p-1'
                  style={{ position: 'relative', paddingRight: '1rem' }}
                >
                  {!readonly &&
                    <Row style={{ display: 'flex', justifyContent: 'end', paddingRight: '1rem' }}>
                      <X color='#858585' onClick={() => handleRemoveItem(item.id)} style={{ cursor: 'pointer' }} />
                    </Row>
                  }
                  <Row className='mb-1'>
                    <Col xl='12'>
                      <Label for={`input-title-${item.id}`}>Título</Label>
                      <Input
                        id={`input-title-${item.id}`}
                        value={item.title}
                        readOnly={readonly}
                        onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col xl='12'>
                      <Label for={`input-description-${item.id}`}>Descrição</Label>
                      <Input
                        id={`input-description-${item.id}`}
                        value={item.description}
                        readOnly={readonly}
                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl='12'>
                      <Label for={`input-actions-financial-planning-${index}`}>Ações</Label>
                      <DraggableList
                        readonly={readonly}
                        data={item.actions}
                        onChange={(value) => handleDraggableItemsChange(value, index)}
                      />
                    </Col>
                  </Row>
                </Card>
              ))}
              {!readonly &&
                <Button
                  size='sm'
                  className='m-0'
                  color='primary'
                  icon={Plus}
                  text='Adicionar novo problema financeiro'
                  onClick={handleAddItem}
                />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default FinancialProblemsList