/* eslint-disable @typescript-eslint/indent */
/* eslint-disable import/no-cycle */
import { FC } from 'react'
import { Form } from 'components'
import { Modal as ModalReact, ModalBody, ModalHeader } from 'reactstrap'
import { ModalFormProps } from './interfaces'

const ModalForm: FC<ModalFormProps> = ({
  open,
  setOpen,
  title,
  submitText = 'Confirmar',
  children,
  onSubmit,
  register,
  handleSubmit,
  loading,
  size,
  fullButtonConfirm,
  handleClosed
}) => <ModalReact
  isOpen={open}
  size={size}
  onClosed={handleClosed}
  toggle={() => setOpen(!open)}
  className='modal-dialog-centered'
>
    <ModalHeader
      toggle={() => setOpen(!open)}
      close={<button className="close" onClick={() => setOpen(!open)}>×</button>}
    >
      {title}
    </ModalHeader>
    <ModalBody>
      <Form
        buttonLabel={submitText}
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        fullButtonConfirm={fullButtonConfirm ?? false}
        loading={loading}>
        {children}
      </Form>
    </ModalBody>
  </ModalReact>

export default ModalForm